import React from 'react';
import { Space } from 'antd';
import DocumentMeta from 'react-document-meta';

import DownloadLink from 'components/ui/downloadLink';
import { LayoutLending } from 'components/ui/layout-lendiing';
import socialTelegram from 'assets/telegram_black.svg';
import { Video } from 'components/video';
import { BlogSlider } from 'components/blog/slider';
import { OrderAdd } from 'components/order/add';
import { buttonTracking } from 'utils/buttonTracking';
import { getMeta } from 'constants/document';
import { OrderAddChooseColor } from 'components/order';
import ym, { YMInitializer } from 'react-yandex-metrika';
import { ModalActionRecipeForLamination } from 'components/ui/modal/action/recipe-for-lamination';
import { Reviews } from '../../components/reviews';
import { Faq } from '../../components/faq';

export const Home = React.memo(() => {
    const [orderAddVisible, setOrderAddVisible] = React.useState(false);
    const [orderAddChooseColorVisible, setOrderAddChooseColorVisible] = React.useState(false);

    const meta = getMeta({
        type: 'website',
    });

    const orderAddToggle = (v: any = false) => {
        setOrderAddVisible(v);
        if (v) {
            buttonTracking('header', 'Заказать форму');
            ym('reachGoal', 'recipe');
        }
    };

    const orderAddChooseColorToggle = (v: any = false) => {
        setOrderAddChooseColorVisible(v);
        if (v) {
            buttonTracking('header', 'Заказать цвет');
            ym('reachGoal', 'color');
        }
    };

    return (
        <DocumentMeta { ...meta }>
            <LayoutLending>
                <div className="banner">
                    <div className="banner__image">
                        <picture>
                            <source srcSet="/img/banner.png" media="(min-width: 992px)" />
                            <source srcSet="/img/banner-md.png" media="(min-width: 768px)" />
                            <source srcSet="/img/banner.png" media="(min-width: 321px)" />
                            <img src="/img/banner-xs.png" alt="" />
                        </picture>
                    </div>
                    <div className="banner__content">
                        <div className="container">
                            <div className="banner__content-inner">
                                <h1>Hairtone</h1>
                                <p>Подбор цвета волос и рецепта окрашивания профессиональным колористом онлайн</p>
                                <Space>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={ () => orderAddChooseColorToggle(true) }
                                    >
                                        Подобрать цвет 499р
                                    </button>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={ () => orderAddToggle(true) }
                                    >
                                        Разработать рецепт 999р
                                    </button>
                                </Space>
                            </div>
                        </div>
                    </div>
                    <div className="banner__absolute">
                        <div className="banner__social">
                            <DownloadLink
                                to="https://instagram.com/hairtone.ru?utm_medium=copy_link"
                                target="_blank"
                                className="banner__social-item"
                            >
                                <img src="/img/banner-insta.png" alt="" />
                            </DownloadLink>
                            <DownloadLink to="https://vk.com/hairtone" target="_blank" className="banner__social-item">
                                <img src="/img/banner-vk.png" alt="" />
                            </DownloadLink>
                            <DownloadLink to="https://t.me/hairtone" target="_blank" className="banner__social-item">
                                <img src={ socialTelegram } alt="" />
                            </DownloadLink>
                        </div>
                    </div>
                </div>

                <section className="section section--long-bg">
                    <div className="container">
                        { /* <h2 className="h2--regular">Что мы предлагаем?</h2> */ }
                        <div className="content content--long-bg">
                            <div className="content__item content__item--image">
                                <picture>
                                    <img src="/img/img-2v2.png" alt="" />
                                </picture>
                            </div>
                            <div className="content__item content__item--text">
                                <h2>Что мы предлагаем?</h2>
                                <h3>Подбор цвета волос</h3>
                                <p>Вы получите фото окрашиваний, оттенков и перечень рекомендуемых стрижек, которые подойдут вашему типу внешности.</p>
                                <h3>Разработка рецепта окрашивания</h3>
                                <p>Вы получите готовый рецепт: перечень красителей, пропорции смешивания с окислителем, время выдержки + подробные инструкции по нанесению.</p>
                                <Space>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={ () => orderAddChooseColorToggle(true) }
                                    >
                                        Подобрать цвет
                                    </button>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={ () => orderAddToggle(true) }
                                    >
                                        Разработать рецепт
                                    </button>
                                </Space>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section" id="how_it_works">
                    <div className="container">
                        <h2 className="h2--regular">Как это работает?</h2>
                        <div className="content content--image-bg">
                            <div className="content__item content__item--video">
                                <Video />
                                { /* <h3 className="content__item--video__text">КАК ИСПОЛЬЗОВАТЬ ГАЙД ДЛЯ ТОНИРОВАНИЯ, <br /> НАНЕСЕНИЕ КРАСИТЕЛЯ И РЕЗУЛЬТАТ ДО/ПОСЛЕ */ }
                                { /* </h3> */ }
                            </div>
                            <div className="content__item content__item--text">
                                <p>1. Вы прикрепляете фото своих волос и результата, который хотите получить <br /><br />
                                    2. Мы разрабатываем рецепт (соотношение нужных цветов красителя) для окрашивания/тонирования <br /><br />
                                    3. Вы получаете перечень необходимых красителей, пропорцию смешивания и инструкцию по нанесению <br /><br />
                                    4. Покупаете необходимые красители в любом магазине <br /><br />
                                    5. Красите волосы дома по нашей инструкции
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <Reviews />

                <section className="section section--price" id="price">
                    <div className="container">
                        <h2 className="h2--regular">Цены</h2>
                        <div className="content content--price">
                            <div className="content__item content__item--title">
                                <h2>Подобрать цвет <br /> по вашим фото онлайн</h2>
                            </div>
                            <div className="content__item content__item--price">
                                { /* <div className="price"> */ }
                                { /*    <div className="price__item"> */ }
                                { /*        <div className="price__title">Подбор цвета</div> */ }
                                { /*        <div className="price__number">350 ₽</div> */ }
                                { /*    </div> */ }
                                { /* </div> */ }
                            </div>
                            <div className="content__item content__item--btn">
                                <button
                                    type="button"
                                    onClick={ () => orderAddChooseColorToggle(true) }
                                    className="btn"
                                >
                                    499 ₽
                                </button>
                            </div>
                        </div>
                        <div className="content content--price">
                            <div className="content__item content__item--title">
                                <h2>Разработать рецепт + подробные инструкции</h2>
                            </div>
                            <div className="content__item content__item--price">
                                { /* <div className="price"> */ }
                                { /*    <div className="price__item"> */ }
                                { /*        <div className="price__title">Тонирование</div> */ }
                                { /*        <div className="price__number">700 ₽</div> */ }
                                { /*    </div> */ }
                                { /*    <div className="price__item"> */ }
                                { /*        <div className="price__title">Окрашивание</div> */ }
                                { /*        <div className="price__number">700 ₽</div> */ }
                                { /*    </div> */ }
                                { /*    <div className="price__item"> */ }
                                { /*        <div className="price__title">Ламинирование</div> */ }
                                { /*        <div className="price__number">700 ₽</div> */ }
                                { /*    </div> */ }
                                { /* </div> */ }
                            </div>
                            <div className="content__item content__item--btn">
                                <button
                                    type="button"
                                    onClick={ () => orderAddToggle(true) }
                                    className="btn"
                                >
                                    999 ₽
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section accordion-section" id="faq">
                    <div className="container">
                        <h2>Часто задаваемые <br /> вопросы</h2>
                        <Faq />
                    </div>
                </section>

                <BlogSlider />
                { orderAddVisible && (
                    <OrderAdd visible={ orderAddVisible } onCancel={ () => orderAddToggle(false) } />) }
                { orderAddChooseColorVisible && (
                    <OrderAddChooseColor
                        visible={ orderAddChooseColorVisible }
                        onCancel={ () => orderAddChooseColorToggle(false) }
                    />
                ) }
                <YMInitializer accounts={ [85664005] } />
            </LayoutLending>
            <ModalActionRecipeForLamination />
        </DocumentMeta>
    );
});
